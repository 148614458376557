import React, { useEffect, useRef, useState } from 'react';
import './Collapsable.less';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import AnimateHeight from 'react-animate-height';

type Props = {
    triggerText: string | React.ReactNode;
    arrowPosition?: 'left' | 'right' | 'none';
    children: React.ReactNode;
    className?: string;
    onChange?: (open: boolean) => void;
    open?: boolean; // Controlled mode
    defaultOpen?: boolean; // Uncontrolled mode initial state
    showChildrenDirect?: boolean;
    hideTriggerTextIfOpen?: boolean;
};

const Collapsable = ({
    triggerText,
    children,
    className,
    onChange,
    open,
    defaultOpen = false,
    arrowPosition = 'left',
    showChildrenDirect = false,
    hideTriggerTextIfOpen = false,
}: Props) => {
    const isControlled = open != null;
    const [internalOpen, setInternalOpen] = useState(defaultOpen);

    const contentRef = useRef<HTMLDivElement>(null);

    // Sync state in controlled mode
    useEffect(() => {
        if (isControlled) {
            setInternalOpen(open);
        }
    }, [open, isControlled]);

    // Notify parent when `internalOpen` changes
    useEffect(() => {
        if (onChange) {
            onChange(internalOpen);
        }
    }, [internalOpen, onChange]);

    const toggleOpen = () => {
        if (!isControlled) {
            setInternalOpen((prev) => !prev);
        }
    };

    if (showChildrenDirect) {
        return <>{children}</>;
    }

    return (
        <div className="collapsable w-full">
            {!(internalOpen && hideTriggerTextIfOpen) && (
                <div
                    className={classNames(
                        'collapsable-trigger cursor-pointer flex items-center -ml-1',
                        {
                            'justify-between flex-row-reverse': arrowPosition === 'right',
                        },
                        className,
                    )}
                    onClick={toggleOpen}
                >
                    {arrowPosition !== 'none' && (
                        <Icon
                            path={Icon.Path.mdiChevronRight}
                            className={classNames('w-5 transform transition flex-shrink-0', {
                                'rotate-90': internalOpen,
                            })}
                        />
                    )}
                    <div className="flex-grow">{triggerText}</div>
                </div>
            )}

            <AnimateHeight
                duration={150}
                height={!internalOpen ? 0 : 'auto'}
                animateOpacity={true}
                onHeightAnimationEnd={() => {
                    if (internalOpen && contentRef.current) {
                        contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }
                }}
            >
                <div
                    ref={contentRef}
                    className={classNames('collapsable-content origin-top transition-all', { 'collapsable-content-open': internalOpen })}
                    style={{ height: !internalOpen ? 0 : 'auto' }}
                >
                    {children}
                </div>
            </AnimateHeight>
        </div>
    );
};

export default Collapsable;
