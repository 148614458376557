import React, { ReactChild, ReactNode, useEffect, useRef, useState } from 'react';
import './Dropdown.less';
import { Listbox } from '@headlessui/react';
import Icon from '../Icon/Icon';
import AnimateHeight from 'react-animate-height';
import classNames from 'classnames';

export interface DropdownEntryType {
    id: string;
    value: string;
    name: string | ReactChild | ReactNode;
}

interface DropdownParams {
    values: DropdownEntryType[];
    value: any;
    onChange: (value: string) => void;
    placeholder?: string;
    allowCancel?: boolean;
    primary?: boolean;
    isAutoScrollToLastOptionEnabled?: boolean;
    dropdownPosition?: 'relative' | 'absolute';
}

const Dropdown = ({
    values,
    value,
    onChange,
    placeholder,
    primary = false,
    allowCancel = false,
    isAutoScrollToLastOptionEnabled = true,
    dropdownPosition = 'absolute',
}: DropdownParams) => {
    const selectedValue: DropdownEntryType | undefined = values.find((v) => v.value === value);

    const [reRenderIfClosed, reRender] = useState(0);

    const [nullValueVisible, setNullValueVisible] = useState(true);

    useEffect(() => {
        setNullValueVisible(!!value);
    }, [reRenderIfClosed]);

    const listBoxRef = useRef<HTMLDivElement>(null);

    return (
        <Listbox className={'relative'} value={value} onChange={onChange} as={'div'} ref={listBoxRef}>
            {({ open }) => {
                return (
                    <>
                        <Listbox.Button
                            className={classNames('w-full flex items-center bg-gray-100 p-1 rounded-md mb-2 relative', {
                                'button--primary': primary,
                            })}
                        >
                            <span
                                className={classNames('flex-1 leading-8 h-8', {
                                    'button-text-wrapper': primary,
                                })}
                            >
                                {selectedValue?.name || placeholder || 'Bitte wählen'}
                            </span>
                            <Icon
                                className={classNames('absolute right-4 h-8 text-gray-400', {
                                    'button-text-wrapper': primary,
                                })}
                                path={Icon.Path.mdiChevronDown}
                            />
                        </Listbox.Button>

                        <AnimateHeight
                            duration={150}
                            height={open ? 'auto' : 0}
                            className={classNames('shadow-md', {
                                'absolute z-10 right-0 left-0': dropdownPosition === 'absolute',
                                relative: dropdownPosition === 'relative',
                            })}
                            animateOpacity={true}
                            onHeightAnimationEnd={() => {
                                if (open && listBoxRef.current && isAutoScrollToLastOptionEnabled) {
                                    listBoxRef.current.scrollIntoView({ behavior: 'smooth' });
                                }
                                reRender(new Date().getTime());
                            }}
                        >
                            <Listbox.Options static={true} className={'relative w-full bg-gray-50 rounded-b-md -mt-1.5 divide-gray-200 divide-y'}>
                                {allowCancel && value && nullValueVisible && (
                                    <Listbox.Option value={undefined} className={'px-4 py-3 cursor-pointer hover:bg-primary-lighter'}>
                                        <li className={'flex flex-row items-center'}>
                                            <div className={'w-5'} />
                                            <span className={'ml-2 italic'}>{placeholder || 'Bitte wählen'}</span>
                                        </li>
                                    </Listbox.Option>
                                )}

                                {values.map((entry) => (
                                    <Listbox.Option className={'px-4 py-3 cursor-pointer hover:bg-primary-lighter'} key={entry.id} value={entry.value}>
                                        {({ selected }) => (
                                            <ul>
                                                <li className={'flex flex-row items-center'}>
                                                    {selected ? <Icon className={'w-5 text-primary'} path={Icon.Path.mdiCheck} /> : <div className={'w-5'} />}
                                                    <span className={'ml-2 flex-grow'}>{entry.name}</span>
                                                </li>
                                            </ul>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </AnimateHeight>
                    </>
                );
            }}
        </Listbox>
    );
};

export default Dropdown;
