import React, { useCallback, useEffect, useState } from 'react';
import './JobComment.less';
import Dropdown, { DropdownEntryType } from '../../../../components/envago/Dropdown/Dropdown';
import TextArea from '../../../../components/envago/TextArea/TextArea';
import { useTranslation } from 'react-i18next';
import { DefaultJobComment } from '../../../../redux/application/application.types';

const JobComment = ({
    textOnly = false,
    onChange,
    additionalComments,
    primary = false,
    isFreeTextOptionEnabled = true,
    dropdownPosition = 'absolute',
}: {
    textOnly?: boolean;
    onChange: (text: string | undefined) => void;
    additionalComments?: DefaultJobComment[];
    primary?: boolean;
    isFreeTextOptionEnabled?: boolean;
    dropdownPosition?: 'relative' | 'absolute';
}) => {
    const { t } = useTranslation();
    const [comment, setComment] = useState(undefined);
    const [freeTextComment, setFreeTextComment] = useState('');

    const [showInput, setShowInput] = useState(false);

    useEffect(() => {
        if (comment !== 'Freitext') {
            onChange(comment);
        } else {
            onChange(freeTextComment);
        }
    }, [comment, freeTextComment]);

    const valueChange = useCallback((value) => {
        setComment(value);
        if (value === 'Freitext') {
            setShowInput(true);
        } else {
            setShowInput(false);
            setFreeTextComment('');
        }
    }, []);

    const setFreeTextValue = useCallback((value) => {
        setFreeTextComment(value);
    }, []);

    return (
        <div className="job-comment">
            <Dropdown
                dropdownPosition={dropdownPosition}
                primary={primary}
                placeholder={t('jobs.details.readingHint.placeholder')}
                allowCancel={true}
                values={[
                    !textOnly && {
                        value: 'Zähler wurde gewechselt',
                        id: 'Wechselname',
                        name: t('jobs.details.readingHint.meterChanged'),
                    },
                    ...((!textOnly && additionalComments) || []).map((additionalComment) => {
                        return {
                            value: additionalComment.text,
                            id: additionalComment.text,
                            name: t(`job.details.readingHint.custom.${additionalComment.text}`, additionalComment.text),
                        };
                    }),
                    isFreeTextOptionEnabled
                        ? {
                              value: 'Freitext',
                              id: 'Freitext',
                              name: t('jobs.details.readingHint.freetext.label'),
                          }
                        : null,
                ]
                    .filter((value) => !!value)
                    .map((element) => element as DropdownEntryType)}
                value={comment}
                onChange={valueChange}
            />

            {isFreeTextOptionEnabled && showInput && (
                <TextArea
                    hasFocus={showInput}
                    maxLength={1000}
                    placeholder={t('jobs.details.readingHint.freetext.placeholder')}
                    value={freeTextComment}
                    onChange={setFreeTextValue}
                />
            )}
        </div>
    );
};

export default JobComment;
