import React, { useEffect, useMemo, useState } from 'react';
import './ShareJobDialog.less';
import Button from '../../../components/envago/Button/Button';
import Dialog from '../../../components/envago/Dialog/Dialog';
import { JobType } from '../../../redux/jobs/jobs.types';
import LabeledList from '../../../components/envago/LabeledList/LabeledList';
import OptionalLabel from '../../../components/envago/OptionalLabel/OptionalLabel';
import Input from '../../../components/envago/Input/Input';
import Checkbox from '../../../components/envago/Checkbox/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import { sharesActions, sharesSelectors } from '../../../redux/shares';
import Loading from '../../../components/common/Loading/Loading';
import ClipboardText from '../../../components/envago/ClipboardText/ClipboardText';
import { useTranslation } from 'react-i18next';
import Collapsable from '../../../components/envago/Collapsable/Collapsable';
import ShareAccountEmail from './ShareAccountEmail';
import ExportExcel from '../ExportExcel/ExportExcel';
import { applicationSelectors } from '../../../redux/application';

const ShareJobDialog = ({ jobs, visible, onClose }: { jobs: JobType[]; visible: boolean; onClose: (open: boolean) => void }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [shareName, setShareName] = useState('');
    const [reshare, setReshare] = useState(false);

    const [accountId, setAccountId] = useState('');

    useEffect(() => {
        /*
           this is necessary, through jobs might be set after creation
         */
        setAccountId(jobs.find((a) => a)?.login || '');
    }, [jobs]);

    // Note: The close button will be enabled if the user has interacted with the primaryLoginSharingMethod or has opened the collapse to inspect other login methods.
    const [isCloseEnabled, setIsCloseEnabled] = useState(false);

    const isSending = useSelector(sharesSelectors.createIsSending);
    const createdAccount = useSelector(sharesSelectors.createGetAccount);
    const { excelExportAllowed, sendSubaccountViaEmailDisabled } = useSelector(applicationSelectors.getFeaturesConfig);

    const primaryLoginSharingMethod = useMemo(() => {
        if (sendSubaccountViaEmailDisabled) {
            return (
                <LabeledList
                    title={t('jobs.share.success.loginMethod.direct.title')}
                    description={t('jobs.share.success.loginMethod.direct.description')}
                    className={'primary-login-sharing-method'}
                >
                    <OptionalLabel label={t('jobs.share.labels.tokenLogin')}>
                        <ClipboardText
                            showText={`${window?.location?.origin}/m/${createdAccount?.token}`}
                            text={`${window?.location?.origin}/m/${createdAccount?.token}`}
                            onCopiedToClipboard={() => {
                                setIsCloseEnabled(true);
                            }}
                        />
                    </OptionalLabel>
                </LabeledList>
            );
        }

        return <ShareAccountEmail onEmailsSend={(isEmailsSend) => setIsCloseEnabled(isEmailsSend)} accountId={accountId} />;
    }, [createdAccount, sendSubaccountViaEmailDisabled, accountId, t]);

    useEffect(() => {
        dispatch(sharesActions.createSubAccountReset());
        setShareName('');
    }, [visible]);

    return (
        <>
            <Dialog
                title={t('jobs.share.title')}
                onClose={onClose}
                closable={true}
                classNameContent={'dialog-content'}
                content={
                    <div className="max-w-screen-lg">
                        {!isSending && !createdAccount && (
                            <div>
                                <LabeledList>
                                    <OptionalLabel label={t('jobs.share.labels.accessName')} description={t('jobs.share.descriptions.accessName')}>
                                        <Input
                                            placeholder={t('jobs.share.placeholders.name')}
                                            value={shareName}
                                            autoFocus={true}
                                            onChange={setShareName}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') e.currentTarget.blur();
                                            }}
                                        />
                                    </OptionalLabel>

                                    <OptionalLabel label={t('jobs.share.labels.additionalOptions')} description={t('jobs.share.descriptions.reshareAllowed')}>
                                        <Checkbox value={reshare} onChange={setReshare} className={'mb-3'} label={t('jobs.share.labels.reshareAllowed')} />
                                    </OptionalLabel>

                                    <OptionalLabel>
                                        <Button
                                            block={true}
                                            primary={true}
                                            disabled={!shareName}
                                            onClick={() => {
                                                dispatch(
                                                    sharesActions.createSubAccount(
                                                        {
                                                            allowToReShare: reshare,
                                                            name: shareName,
                                                            jobIds: jobs.flatMap((job) => job.id),
                                                        },
                                                        jobs.find((job) => !!job)?.login || '',
                                                    ),
                                                );
                                            }}
                                        >
                                            {t('jobs.share.buttons.createAccess')}
                                        </Button>
                                    </OptionalLabel>
                                </LabeledList>
                            </div>
                        )}
                        {isSending && <Loading text={t('jobs.share.sending')} />}
                        {createdAccount && (
                            <div className="max-w-screen-lg">
                                <div>
                                    <p>
                                        {t('jobs.share.success.summary', {
                                            name: shareName,
                                            count: jobs.length,
                                        })}
                                    </p>
                                    <p>{t('jobs.share.success.message')}</p>
                                </div>

                                {primaryLoginSharingMethod}

                                <Collapsable
                                    onChange={(visible) => {
                                        setIsCloseEnabled(visible);
                                    }}
                                    className={'mt-6'}
                                    triggerText={t('jobs.share.success.loginMethod.triggerText')}
                                >
                                    {!sendSubaccountViaEmailDisabled && (
                                        <LabeledList
                                            title={t('jobs.share.success.loginMethod.direct.title')}
                                            description={t('jobs.share.success.loginMethod.direct.description')}
                                            className={'mt-2'}
                                        >
                                            <OptionalLabel label={t('jobs.share.labels.tokenLogin')}>
                                                <ClipboardText
                                                    showText={`${window?.location?.origin}/m/${createdAccount.token}`}
                                                    text={`${window?.location?.origin}/m/${createdAccount.token}`}
                                                />
                                            </OptionalLabel>
                                        </LabeledList>
                                    )}

                                    <LabeledList
                                        title={t('jobs.share.success.loginMethod.web.title')}
                                        description={t('jobs.share.success.loginMethod.web.description')}
                                        className={'mt-2'}
                                    >
                                        <OptionalLabel label={t('jobs.share.labels.webLogin')}>
                                            <ClipboardText showText={`${window?.location?.origin}`} text={`${window?.location?.origin}`} />
                                        </OptionalLabel>
                                        <OptionalLabel label={t('jobs.share.labels.username')}>
                                            <ClipboardText text={createdAccount.username} />
                                        </OptionalLabel>
                                        <OptionalLabel label={t('jobs.share.labels.password')}>
                                            <ClipboardText text={createdAccount.password} />
                                        </OptionalLabel>
                                    </LabeledList>

                                    {excelExportAllowed && (
                                        <LabeledList
                                            title={t('management.accounts.share.exportXlsx.title')}
                                            description={t('management.accounts.share.exportXlsx.description')}
                                            className={'mt-2'}
                                        >
                                            <OptionalLabel label={t('management.accounts.share.exportXlsx.label')}>
                                                <ExportExcel jobs={jobs} />
                                            </OptionalLabel>
                                        </LabeledList>
                                    )}
                                </Collapsable>
                                <LabeledList className={'mt-4'}>
                                    <OptionalLabel>
                                        <Button
                                            disabled={!isCloseEnabled}
                                            block={true}
                                            onClick={() => {
                                                onClose(false);
                                            }}
                                        >
                                            {t('jobs.share.buttons.close')}
                                        </Button>
                                    </OptionalLabel>
                                </LabeledList>
                            </div>
                        )}
                    </div>
                }
                visible={visible}
            />
        </>
    );
};

export default ShareJobDialog;
