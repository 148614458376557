import React, { useEffect, useMemo, useState } from 'react';
import './JobDetailsAddUnknown.less';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { push } from 'connected-react-router';

import { notificationActions } from '../../../../redux/notification';
import { unknownReadingActions } from '../../../../redux/unknownReading';
import { applicationSelectors } from '../../../../redux/application';
import { loginSelectors } from '../../../../redux/login';
import { NotificationType } from '../../../MainScreen/NotificationWrapper/NotificationWrapper';
import { UnknownMeterReadingResultType } from '../../../../redux/unknownReading/unknownReading.types';

import BasicScreen from '../../../common/BasicScreen/BasicScreen';
import Card from '../../../../components/envago/Card/Card';
import LabeledList from '../../../../components/envago/LabeledList/LabeledList';
import OptionalLabel from '../../../../components/envago/OptionalLabel/OptionalLabel';
import Input from '../../../../components/envago/Input/Input';
import Toolbar from '../../../../components/envago/Toolbar/Toolbar';
import MeterReadingInput from '../../../../components/envago/MeterReadingInput/MeterReadingInput';
import UploadImage from '../../../../components/envago/UploadImage/UploadImage';
import DatePicker from '../../../../components/envago/DatePicker/DatePicker';
import JobComment from '../JobComment/JobComment';
import PopConfirmButton from '../../../../components/envago/PopConfirmButton/PopConfirmButton';
import Icon from '../../../../components/envago/Icon/Icon';
import Dropdown from '../../../../components/envago/Dropdown/Dropdown';

interface UnknownMeterReadingStateType {
    meterNumber: string;
    readingValue: string;
    images: string[];
    readingDate: Date;
    comment?: string;
}

interface JobDetailsAddUnknownProps {
    className?: string;
}

const JobDetailsAddUnknown = ({ className }: JobDetailsAddUnknownProps) => {
    const [hasFocus, setHasFocus] = useState({
        meterNumberInput: false,
        readingValueInput: false,
    });
    const [accountId, setAccountId] = useState('');
    const [instanceKey, setInstanceKey] = useState<number>(0);
    const [unknownReading, setUnknownReading] = useState<UnknownMeterReadingStateType>({
        meterNumber: '',
        readingValue: '',
        images: [],
        readingDate: dayjs().toDate(),
        comment: undefined,
    });

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const isUnknownReadingsEnabled = useSelector(applicationSelectors.isUnknownReadingsEnabled);
    const isUnknownReadingsImageRequired = useSelector(applicationSelectors.isUnknownReadingsImageRequired);
    const isQueryPostDecimalsEnabled = useSelector(applicationSelectors.isQueryPostDecimalsEnabled);
    const isMaskPostDecimalsEnabled = useSelector(applicationSelectors.isMaskPostDecimalsEnabled);

    const applicationName = useSelector(applicationSelectors.getApplicationName);
    const loginCounts = useSelector(loginSelectors.getLoginCount);
    const logins = useSelector(loginSelectors.getLogins);

    const loginDropdownValues = useMemo(() => {
        let values: any[] = [];
        logins.map(([key, login]) =>
            values.push({
                id: login.info?.id,
                value: key,
                name: login.info?.name || t('jobs.unknownMeterReading.loginDropdown.optionFallback'),
            }),
        );
        return values;
    }, [logins, t]);

    const isValid: boolean = useMemo(() => {
        return (
            (isUnknownReadingsImageRequired ? unknownReading.images.length > 0 && unknownReading.images.length <= 10 : true) &&
            unknownReading.meterNumber.length > 0 &&
            unknownReading.readingValue.length > 0 &&
            unknownReading.readingDate !== null
        );
    }, [unknownReading, isUnknownReadingsImageRequired]);

    useEffect(() => {
        if (!isUnknownReadingsEnabled) {
            dispatch(push('/ablesung'));
            dispatch(
                notificationActions.showNotification(
                    t('jobs.unknownMeterReading.notAllowedNotification.title'),
                    t('jobs.unknownMeterReading.notAllowedNotification.message'),
                    NotificationType.ERROR,
                ),
            );
        }
    }, [isUnknownReadingsEnabled, t, dispatch]);

    useEffect(() => {
        if (loginCounts === 1) {
            for (const [accountId] of logins) {
                setAccountId(accountId);
            }
        }
    }, [logins, loginCounts]);

    return (
        <BasicScreen>
            <Toolbar showBackButton={true} title={t('jobs.unknownMeterReading.heading')} />
            <div>
                <p className={'text-md mb-4 text-gray-500'}>
                    <Trans i18nKey={'jobs.unknownMeterReading.description'}>
                        Gern können Sie Zählerstände für Zähler erfassen, welche nicht in dieser Ableseliste angefragt wurden. Diese zusätzlichen Stände werden
                        nach der Erfassung direkt an <strong>{{ applicationName }}</strong> weitergeleitet. Bitte beachten Sie, dass diese zusätzlich erfassten
                        Stände nach der Weiterleitung hier nicht mehr angezeigt werden.
                    </Trans>
                </p>
            </div>
            <Card className={classNames('job-details-add-unknown', className)}>
                <LabeledList>
                    {loginCounts > 1 && (
                        <OptionalLabel label={t('management.accounts.title')}>
                            <Dropdown
                                placeholder={t('jobs.unknownMeterReading.loginDropdown.placeholder')}
                                values={loginDropdownValues}
                                value={accountId}
                                onChange={(value) => {
                                    setAccountId(value);
                                }}
                            />
                        </OptionalLabel>
                    )}
                </LabeledList>
            </Card>
            <Card>
                <LabeledList>
                    <OptionalLabel label={`${t('jobs.details.labels.meterNumber')} *`}>
                        <div className={'w-full flex items-center md:w-max md:justify-start justify-center'}>
                            <Input
                                className={'job-details-add-unknown-meter-number-input whitespace-nowrap inline-block'}
                                placeholder={'563783929'}
                                value={unknownReading.meterNumber}
                                onChange={(value) => {
                                    setUnknownReading({
                                        ...unknownReading,
                                        meterNumber: value,
                                    });
                                }}
                                onFocusChange={(focus) => {
                                    setHasFocus({ ...hasFocus, meterNumberInput: focus });
                                }}
                            />
                            <div className={'lg:ml-3 relative'}>
                                {!hasFocus.meterNumberInput && unknownReading.meterNumber && (
                                    <Icon path={Icon.Path.mdiCheck} className={'h-6 text-green-500 absolute left-1 -top-3'} />
                                )}
                            </div>
                        </div>
                    </OptionalLabel>
                    <OptionalLabel label={`${t('jobs.details.labels.reading')} *`} className={'flex items-center flex-wrap xl:flex-nowrap'}>
                        <div className={'w-full flex items-center md:w-max md:justify-start justify-center'}>
                            <MeterReadingInput
                                maskPostDecimals={isMaskPostDecimalsEnabled}
                                key={instanceKey}
                                preDecimals={9}
                                postDecimals={isQueryPostDecimalsEnabled ? 3 : 0}
                                onChange={(value) => {
                                    setUnknownReading({
                                        ...unknownReading,
                                        readingValue: value,
                                    });
                                }}
                                onFocusChange={(focus) => {
                                    setHasFocus({ ...hasFocus, readingValueInput: focus });
                                }}
                            />
                            <div className={'lg:ml-3 relative'}>
                                {!hasFocus.readingValueInput && unknownReading.readingValue && (
                                    <Icon path={Icon.Path.mdiCheck} className={'h-6 text-green-500 absolute left-1 -top-3'} />
                                )}
                            </div>
                        </div>
                    </OptionalLabel>
                    <OptionalLabel label={`${t('jobs.details.labels.readingImage')} ${isUnknownReadingsImageRequired ? '*' : ''}`}>
                        <UploadImage
                            key={instanceKey}
                            text={t('jobs.details.buttons.uploadImage')}
                            onChange={(images) => {
                                setUnknownReading({
                                    ...unknownReading,
                                    images: images,
                                });
                            }}
                        />
                    </OptionalLabel>
                    <OptionalLabel label={`${t('jobs.details.labels.readingDate')} *`}>
                        <DatePicker
                            value={unknownReading.readingDate}
                            minDate={dayjs().subtract(30, 'days').toDate()}
                            maxDate={dayjs().toDate()}
                            onChange={(date) => {
                                setUnknownReading({
                                    ...unknownReading,
                                    readingDate: date,
                                });
                            }}
                        />
                    </OptionalLabel>
                    <OptionalLabel label={t('jobs.details.labels.readingHint')}>
                        <JobComment
                            key={instanceKey}
                            textOnly={true}
                            onChange={(text) => {
                                setUnknownReading({
                                    ...unknownReading,
                                    comment: text,
                                });
                            }}
                        />
                    </OptionalLabel>
                    <OptionalLabel className={'lg:mt-0 mt-2 flex flex-col'}>
                        {!isValid && <p className={'text-sm text-gray-400 flex-end'}>{t('common.fillRequiredFields')}</p>}
                        <PopConfirmButton
                            primary={true}
                            block={true}
                            okText={t('jobs.details.send.confirm')}
                            cancelText={t('jobs.details.send.cancel')}
                            disabled={!isValid}
                            isConfirmed={isValid}
                            onClick={() => {
                                let result: UnknownMeterReadingResultType = {
                                    meterNumber: unknownReading.meterNumber,
                                    readingValue: Number.parseFloat(unknownReading.readingValue),
                                    readingImages: unknownReading.images
                                        .map((image) => {
                                            let imageMatch = image.match(/.*data:(.*);.*,(.*)/m);
                                            if (imageMatch) {
                                                return {
                                                    encodedImage: imageMatch[2],
                                                    mimeType: imageMatch[1],
                                                };
                                            }
                                            return {
                                                encodedImage: '',
                                                mimeType: '',
                                            };
                                        })
                                        .filter((image) => image.encodedImage.length > 0),
                                    readingDate: unknownReading.readingDate.toISOString(),
                                    comment: unknownReading.comment && unknownReading.comment,
                                };

                                dispatch(unknownReadingActions.sendUnknownMeterReadingResult(result, accountId));

                                setUnknownReading({
                                    meterNumber: '',
                                    readingValue: '',
                                    images: [],
                                    readingDate: dayjs().toDate(),
                                    comment: undefined,
                                });

                                setInstanceKey(instanceKey + 1);
                            }}
                        >
                            {t('jobs.details.buttons.send')}
                        </PopConfirmButton>
                    </OptionalLabel>
                </LabeledList>
            </Card>
        </BasicScreen>
    );
};

export default JobDetailsAddUnknown;
