export default {
    'update': {
        'dialog': {
            'title': 'Neue Version aktivieren',
            'content': 'Es wurde eine neue Version dieser Anwendung geladen, die nun aktiviert werden kann.',
            'hint':  'Durch die Aktivierung wird die Seite neu geladen. Nicht gespeicherte Eingaben gehen hierbei eventuell verloren.',
            'buttonNegative': 'Später',
            'buttonPositive': 'Jetzt aktivieren'
        }
    },
    'login': {
        'title': 'Willkommen bei der Ablesung der {{applicationName}}',
        'subtitle': 'Bitte melde dich mit den Zugangsdaten an, die du von uns erhalten hast:',
        'qrcode': {
            'error': {
                'access': {
                    'dialogtitle': 'Kamera Zugriff nicht möglich',
                    'title': 'Leider konnten wir nicht auf deine Kamera zugreifen.',
                    'text': 'Bitte melde dich mit deinen Zugangsdaten an.',
                },
                'device': {
                    'title': 'Die Anmeldung über die Kamera ist leider mit deinem Gerät nicht möglich - oder du hast uns keinen Zugriff auf deine Kamera erlaubt.',
                },
            },
            'teaser': {
                'text': 'Du hast einen QR-Code auf einer Ablesekarte oder in einem Brief erhalten? Und du hast eine Webcam?<1/> Scanne jetzt einfach den QR-Code um schnell und einfach angemeldet zu werden:',
                'access': 'Für die Anmeldung mit QR-Code benötigen wir Zugriff auf deine Kamera.',
            },
            'dialog': {
                'instruction': 'Halte den QR-Code von deiner Ablesekarte oder deinem Brief vor die Kamera:',
            },
            'buttons': {
                'login': 'Mit QR-Code anmelden',
                'close': 'Schließen',
            },
        },
        'labels': {
            'username': 'Benutzername',
            'password': 'Passwort',
            'qr-login': 'QR-Code Anmeldung',
        },
        'placeholders': {
            'username': 'Mein Benutzername',
            'password': 'Passwort',
        },
        'buttons': {
            'login': 'Anmelden',
        },
        'tabs': {
            'login': 'Anmelden mit Zugangsdaten',
            'unknownUserReading': 'Ablesen ohne Zugangsdaten'
        },
        'error': {
            'login': {
                'text': 'Anmeldung fehlgeschlagen. Bitte prüfe deine Anmeldedaten.',
            },
            'dialog': {
                'title': 'Anmeldung nicht möglich',
                'description': 'Tut mir leid, die Anmeldung mit diesen Zugangsdaten ist momentan nicht möglich.',
                'content': ' Wenn du eine Ablesekarte erhalten hast, so wurden die Daten der Karte eventuell noch nicht übermittelt.<1 /><2 />Probiere es einfach später nochmal!',
                'buttons': {
                    'close': 'Alles klar',
                    'unknownUserReading': 'Ablesung ohne Anmeldung'
                },
                'unknownUserReading': 'Alternativ kannst du hier deinen Zählerstand ohne Anmeldung übermitteln:',
            },
        },
        'usage': {
            'text': 'Diese Seite wird von {{applicationName}} bereitgestellt. Mit der Anmeldung und Nutzung erklärst du dich mit den <3>Datenschutzbedingungen</3> einverstanden.',
        },
    },
    'unknownUserReading': {
        'loginScreen': {
            'text': 'Du möchtest uns einen Zählerstand mitteilen, hast aber keinen Brief von uns erhalten?',
            'buttonText': 'Ablesung ohne Zugang'
        },
        'doneScreen': {
            'title': 'Deine Ablesung ist erledigt',
            'message': 'Vielen Dank. Dein Zählerstand wurde erfolgreich übermittelt.'
        },
        'screenTitle': 'Willkommen zur Ablesung ohne Zugangsdaten',
        'errorHandling': {
            'isAuthPending':'Du wirst angemeldet, bitte warten.',
            'isAuthError': 'Fehler beim Anmelden. Versuche es bitte später erneut.',
            'tokenExpired': 'Deine Sitzung ist abgelaufen, Bitte melden dich erneut an.',
            'featureNotAvailable': 'Das Ablesen ohne Zugangsdaten ist aktuell nicht möglich. Versuche es bitte später erneut.',
        },
        'featureTitle': 'Zählerstand mitteilen',
        'introduction': {
            'screenHint': 'Du kannst uns deinen Zählerstand auch ohne Anmeldung mitteilen.',
            'pinReceivedHint': 'Wenn Du Zugangsdaten per Brief oder eine Ablesekarte von unserem Ableser erhalten hast, dann melden dich bitte hier an:'
        },
        'meterStep': {
            'title': 'Zähler auswählen',
            'description': 'Bitte gibt zunächst deine Zählernummer ein und wähle die Art deines Zählers aus.',
            'validationError': 'Bitte gib die Zählernummer vollständig an.'
        },
        'meterReadingStep': {
            'title': 'Ablesung durchführen',
            'description': 'Bitte trage nun die Zählerstände deines Zählers {{preposition}} Nachkommastellen ein.',
            'validationError': 'Bitte gib die Zählerstände vollständig an.'
        },
        'contactStep': {
            'title': 'Kontaktdaten angeben',
            'description': 'Damit wir deiner Angaben korrekt zuordnen können, benötigen wir deine Kontaktdaten und die Adresse des Zählers.',
            'validationError': 'Bitte gib alle Pflichtfelder (*) an.'
        },
        'summaryStep': {
            'title': 'Zusammenfassung',
            'description': 'Lorem Ipsum kontrolliere deine Daten ...'
        },
        'reading': {
            'title': 'Zählerstand mitteilen'
        },
        'information': {
            'meterNumberHint': 'Für die korrekte Zuordnung deiner Ablesung benötigen wir die vollständige Zählernummer.',
            'emailProcessing': 'Hinweis: Die Nutzung der E-Mail-Funktion erfolgt freiwillig. Die E-Mail-Adresse wird ohne Ihre Zustimmung nicht dauerhaft gespeichert.',
            'privacyPolicy': 'Diese Seite wird von {{applicationName}} bereitgestellt. Ich habe die <3>Datenschutzbedingungen</3> gemäß Art. 13 und 14 DS-GVO zur Kenntnis genommen.',
        },
        'notification': {
            'readingRequest': {
                'error': {
                    'title': 'Mitteilung fehlgeschlagen',
                    'description': 'Der Zählerstand konnte nicht übermittelt werden. Versuche es bitte später erneut.',
                },
                'success': {
                    'title': 'Zählerstand mitgeteilt',
                    'description': 'Der Zählerstand wurde erfolgreich übermittelt.',
                }
            },
            'readingReceipt': {
                'error': {
                    'title': 'Kopie per E-Mail fehlgeschlagen',
                    'description': 'Eine Kopie deiner Zählerstände konnte an deine angegebene E-Mail nicht versendet werden.',
                },
                'success': {
                    'title': 'Kopie per E-Mail versendet',
                    'description': 'Eine Kopie deiner Zählerstände wurde an deine angegebene E-Mail versendet.',
                }
            }
        },
        'subTitle': {
            'meterNumber': 'Zählernummer',
            'meterType': 'Zählertyp',
            'meterSubType': 'Untertyp',
            'meterReading': 'Zählerstände',
            'readingDate': 'Ablesedatum',
            'contact': 'Deine Kontaktdaten',
            'meterAddress': 'Adresse des Zählers',
            'readingReason': 'Grund der Ablesung',
            'meter': 'Dein Zähler',
            'readingValues': 'Ablesewerte',
            'email': 'Möchtest du eine Kopie deiner Zählerstände per E-Mail?',
            'privacyPolicy': 'Datenschutzerklärung',
        },
        'description': {
            'readingDate': 'Wann haben wurde der Zählerstand erfasst?',
            'dataPrivacyRequired': 'Zum Übermitteln deiner Daten, stimme bitte der Datenschutzerklärung zu.',
        },
        'label': {
            'meterNumber': 'Zählernummer',
            'meterType': 'Zählertyp',
            'meterSubType': 'Untertyp',
            'queryTransformerOrMWhPostDecimals': 'Nachkommastellen für Wandler- oder MWh-Messung erfassen',
            'contact': 'Kontaktdaten',
            'firstname': 'Vorname',
            'lastname': 'Nachname',
            'meterAddress': 'Adresse des Zählers',
            'street': 'Straße',
            'houseNumber': 'Hausnummer',
            'zip': 'Postleitzahl',
            'city': 'Stadt',
            'readingReason': 'Grund der Ablesung',
            'readingDate': 'Ablesedatum',
            'email': 'E-Mail'

        },
        'placeholder': {
            'meterNumber': 'Zählernummer',
            'firstname': 'Vorname',
            'lastname': 'Nachname',
            'street': 'Straße',
            'houseNumber': 'Hausnummer',
            'zip': 'Postleitzahl',
            'city': 'Stadt',
            'email': 'E-Mail',
        },
        'meterType': {
            'ELECTRIC_METER': 'Stromzähler',
            'GAS_METER': 'Gaszähler',
            'WATER_METER': 'Wasserzähler',
            'WATER_WARM_METER': 'Warmwasserzähler',
            'HEAT_METER': 'Wärmezähler',
        },
        'meterSubType': {
            'ELECTRIC_METER_TARIFFS_ONE': 'Stromzähler (1 Tarif)',
            'ELECTRIC_METER_TARIFFS_TWO': 'Stromzähler (2 Tarife)',
            'ELECTRIC_METER_FEED_IN_METER': 'Stromzähler (Einspeiser)',
            'OTHER': 'anderer'
        },
        'readingReason': {
            'ANNUAL_READING': 'Jahresablesung',
            'CONTROL_READING': 'Kontrollablesung',
            'PROVIDER_CHANGE': 'Lieferantenwechel / Umzug',
        },
        'rateName': {
            'Verbrauch': 'Verbrauch',
            'Einspeisung': 'Einspeisung',
        },
        'button': {
            'refreshToken': 'Erneut anmelden',
            'toHome': 'Zur Startseite zurückkehren',
            'removeMeterRate': 'Tarif entfernen',
            'loginWithPIN': 'mit Zugangsdaten anmelden',
            'continue': 'Weiter',
            'submit': 'Abschicken'
        },
        'preposition': {
            'with': 'mit',
            'without': 'ohne'
        },
    },
    'home': {
        'title': 'Mein Ableseportal - {{applicationName}}',
        'introduction': {
            'title': 'Willkommen',
            'reading': 'Deine Zähler findest du im Menü <1>$t(menu.reading)</1>. Dort kannst du schnell & einfach deine Zählerstände eintragen und abschicken.',
            'management': 'Über den Menüpunkt <1>$t(menu.management)</1> kannst du Ablesungen mit anderen teilen. Als Hausverwaltung teilst du z.B. deine Ablesungen mit deinem Hausverwalter oder den Hausmeistern.',
        },
        'account': {
            'title': 'Mein Zugang',
            'title_plural': 'Meine Zugänge',
            'multipleAccounts': 'Du hast dich mit mehreren Zugängen angemeldet:',
            'login': {
                'info': 'Du bist mit dem Zugang <1>{{name}}</1> angemeldet.',
                'parent': 'Dieser Zugang wurde von <1>{{parent}}</1> für dich erstellt.',
                'shared': 'von {{parent}} mit dir geteilt',
            },
        },
        'readings': {
            'title': 'Meine Ablesungen',
            'open': 'Du hast <1>{{jobCountOpen}} offene</1> Ablesung.',
            'open_plural': 'Du hast <1>{{jobCountOpen}} offene</1> Ablesungen.',
            'done': 'Du hast alle deine Ablesungen erledigt!',
            'labels': {
                'totalReadings': 'Ablesungen gesamt',
                'progress': 'Fortschritt (erledigt)',
            },
            'buttons': {
                'openReadings': '$t(common.reading_plural) öffnen',
            },
        },
    },
    'jobs': {
        'search': {
            'noResults': 'Es konnten keine Ergebnisse für die Suche nach <1/> <3>{{searchValue}}</3> <5/> gefunden werden.',
        },
        'labels': {
            'readingsDone': 'Erledigte $t(common.reading_plural)',
        },
        'share': {
            'title': 'Ablesung teilen',
            'placeholders': {
                'name': 'Hausverwaltung Müller',
                'email': 'max@mustermann.de',
            },
            'descriptions': {
                'accessName': 'Lege einen Namen für den neuen Zugang fest. Der Zugangsname wird dir später in der Übersichtsseite deiner geteilten Zugänge gemeinsam mit dem jeweiligen Fortschritt der Ablesung angezeigt. Der Name ist für den erstellten Zugang ebenfalls sichtbar.',
                'reshareAllowed': 'Durch Aktivierung erlaubst du dem Nutzer dieses Zugangs das erneute Teilen seiner Zähler in weitere Unterzugänge. Falls der von dir erstellte Zugang die Zähler direkt abliest, wird die Aktivierung dieser Funktion nicht benötigt.',
            },
            'defaultShareName': 'Zähler {{meterNumber}}',
            'defaultShareName_plural': 'Zähler {{meterNumber}} u.a.',
            'labels': {
                'accessName': 'Zugangsname',
                'additionalOptions': 'Zusätzliche Optionen',
                'reshareAllowed': 'Darf weiterteilen',
                'accessEmail': 'E-Mails vom Zugang',
                'username': 'Benutzername',
                'password': 'Passwort',
                'tokenLogin': 'Direkt-Link',
                'webLogin': 'Webportal-URL',
                'qrcode': 'QR Code',
            },
            'sending': 'Teile Ablesungen...',
            'success': {
                'summary': 'Der Zugang "{{name}}" wurde erfolgreich erstellt und beinhaltet {{count}} Zähler.',
                'message': 'Du kannst die nachfolgenden Anmeldedaten für diesen Zugang nun weitergeben.',
                'loginMethod': {
                    'email': {
                        'title': 'Zugang per E-Mail versenden',
                        'description':
                            'Du kannst die Anmeldedaten direkt an die E-Mail Adressen des Zugangs versenden.',
                        'notifications': {
                            'valid': 'Die von dir angegebenen E-Mail-Adressen sind gültig.',
                            'warning': 'Die von dir angegebenen E-Mail-Adressen sind nicht gültig!',
                        },
                        'send' : 'Die Zugangsdaten wurden an {{email}} versendet.'
                    },
                    'triggerText': 'weitere Anmeldeoptionen anzeigen',
                    'direct': {
                        'title': 'Direkt-Anmeldung per Link',
                        'description': 'Der Link zur Direkt-Anmeldung beinhaltet bereits die Zugangsdaten.'
                    },
                    'web': {
                        'title': 'Manuelle Anmeldung per Login',
                        'description': 'Optional kannst du die folgenden Anmeldedaten zur manuellen Anmeldung weitergeben.',
                    },
                },
            },
            'buttons': {
                'createAccess': 'Zugang erstellen',
                'copyTokenUrl': 'Zugangs-URL kopieren',
                'send': 'Senden',
                'close': 'Schließen',
            },
        },
        'details': {
            'title': 'Ableseauftrag',
            'doneHint': '<0>Dieser Zähler wurde bereits abgelesen.</0><1/> Du kannst den Zählerstand korrigieren und uns einen neuen Stand schicken.',
            'doneHintLate': '<0>Dieser Zähler wurde bereits abgelesen.</0><1/> Du kannst den Zählerstand korrigieren und uns einen neuen Stand schicken.',
            'doneHintOverdue': '<0>Dieser Zähler wurde bereits abgelesen.</0><1/> Du kannst den Zählerstand korrigieren und uns einen neuen Stand schicken.',
            'sending': 'Sende Daten...',
            'multiObisWarning': 'Dieser Zähler hat <1 className=\'font-medium\'>{{meterRateCount}} Tarife</1>: Bitte beachte die Tarif-Bezeichnungen!',
            'send': {
                'confirm': 'Trotzdem senden',
                'cancel': 'Korrigieren',
            },
            'labels': {
                'meterNumber': '$t(meterReadingJob.meterNumber)',
                'meterType': 'Zählerart',
                'customer': '$t(meterReadingJob.customer.title)',
                'customerNumber': '$t(meterReadingJob.customer.customernumber)',
                'deliveryPoint': 'Adresse',
                'readingValue': 'Ablesewert',
                'reading': '$t(common.reading)',
                'readingImage': 'Ablesefoto',
                'partner': 'Vertragspartner',
                'objectKey': 'Objektschlüssel',
                'locationDescription': 'Beschreibung',
                'hint': 'Hinweis',
                'obis': 'Tarif',
                'readingDate': 'Ablesedatum',
                'readingHint': 'Ablesehinweis',
                'targetReadingDate': '$t(common.targetReadingDate)',
            },
            'readingHint': {
                'placeholder': 'Kein Hinweis',
                'meterChanged': 'Zähler gewechselt',
                'freetext': {
                    'label': 'anderer Hinweis',
                    'placeholder': 'Hinweis zur Ablesung...',
                },
            },
            'warnings': {
                'constraints': {
                    'beforeEarliestExecution': {
                        'title': 'Ablesezeitraum hat noch nicht begonnen',
                        'description': 'Der Zählerstand dieser Ablesung wurde ab dem <1>{{earliestExecution}}</1> angefragt.<br/> Du kannst die Ablesung durchführen, eventuell wird der tatsächliche Verbrauch jedoch geschätzt.',
                    },
                    'afterLatestExecution': {
                        'title': 'Ablesezeitraum ist überschritten',
                        'description': 'Der Zählerstand dieser Ablesung wurde bis zum <1>{{latestExecution}}</1> angefragt.<br/>Du kannst die Ablesung durchführen, eventuell kann der Zählerstand jedoch nicht mehr für die Abrechnung berücksichtigt werden.',
                    },
                    'beforeSendingReadingValuesIsAllowedBasedOnEarliestExecutionAndDuration': {
                        'title': 'Ablesezeitraum hat noch nicht begonnen',
                        'description': 'Der Zählerstand dieser Ablesung kann erst ab dem <1>{{earliestExecution}}</1> übermittelt werden.<br/> Bei Fragen wende dich bitte an den Kundenservice.'
                    },
                    'afterSendingReadingValuesIsAllowedBasedOnLatestExecutionAndDuration': {
                        'title': 'Ablesezeitraum ist überschritten',
                        'description': 'Der Zählerstand dieser Ablesung kann leider nicht mehr übermittelt werden, da der Ablesezeitraum überschritten ist.<br/> Bei Fragen wende dich bitte an den Kundenservice.'
                    },
                },
                'check': {
                    'title': 'Bitte Eingabe prüfen',
                    'message': 'Bitte prüfe die eingegebenen Zählerstände nochmal.',
                    'details': 'Bitte achte darauf, dass du die richtige Anzahl von Nachkommastellen abgelesen hast und nicht versehentlich mit dem Komma `verrutscht` bist.',
                },
                'incomplete': {
                    'title': 'Unvollständige Werte',
                    'message': 'Dein Zähler hat mehrere Tarife, du hast jedoch nur für einen Teil davon Werte eingetragen.',
                    'details': 'Die Abrechnung mit deinem Anbieter kann meist nur korrekt erfolgen, wenn alle Zählerstände vorhanden sind. Fehlen Zählerstände, so werden diese eventuell geschätzt.',
                },
                'invalidText': 'Bitte exakt {{count}} Nachkommastelle angeben.',
                'invalidText_plural': 'Bitte exakt {{count}} Nachkommastellen angeben.',
                'validationWarningText': 'Der Zählerstand ist nicht plausibel, bitte prüfe die Eingabe.',
                'historicValidationWarningText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung.',
                // fixme: is not processed | 'historicValidationErrorText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung. Bitte füge ein Ablesefoto oder einen Ablesehinweis hinzu.',
                'historicValidationFeedInMeterWarningText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung.',
                'continuousReadingsValidationWarningText': 'Der Zählerstand ist nicht plausibel, der Wert ist geringer als bei der letzten Ablesung.',
                'readingDateWarning': 'Ungültiges Ablesedatum ausgewählt: Bitte wähle ein Ablesedatum zwischen dem {{earliest}} und dem {{latest}} aus. Das Ablesedatum wurde auf den {{readingDate}} zurückgesetzt.',
                'imageRequiredError': 'Der Zählerstand ist nicht plausibel. Bitte füge ein Ablesefoto hinzu.',
                'imageOrCommentRequiredError': 'Der Zählerstand ist nicht plausibel. Bitte füge ein Ablesefoto oder einen Ablesehinweis hinzu.',
                // fixme: is not processed | 'imageAndCommentRequiredError': 'Der Zählerstand ist nicht plausibel. Bitte füge ein Ablesefoto und einen Ablesehinweis hinzu.',
                'imageRequiredFeedInMeterError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto hinzu.',
                'imageOrCommentRequiredFeedInMeterError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto oder einen Ablesehinweis hinzu.',
                // fixme: is not processed | 'imageAndCommentRequiredFeedInMeterError': 'Der Zählerstand ist nicht plausibel. Bitte fügen Sie ein Ablesefoto und einen Ablesehinweis hinzu.',
                'hintRequiredError': 'Ein Zählerstand ist nicht plausibel. Bitte gib einen Ablesehinweis an.',
                'hintMinLengthWarning': 'Der Ablesehinweis sollte mindestens 5 Zeichen enthalten.',
                'imageAlwaysRequiredHint': 'Bitte füge ein Foto von deinem Zählerstand hinzu, um dein Ableseergebnis zu senden.',
                'imageAlwaysRequiredHint_plural': 'Bitte füge Fotos von deinen Zählerständen hinzu, um deine Ableseergebnisse zu senden.'
            },
            'tooltips': {
                'readingValueGreaterThanMaximumValue' : 'Der Ablesewert ist größer als erwartet.',
                'readingValueLessThanMinimumValue' : 'Der Ablesewert ist kleiner als erwartet.',
            },
            'imageUploaded': 'Ablesefoto hinzugefügt',
            'buttons': {
                'uploadImage': 'Ablesefoto hinzufügen',
                'send': 'Senden',
                'share': 'Teilen',
                'resendMeterReading' : 'Zählerstand erneut mitteilen',
                'resendMeterReading_plural' : 'Zählerstände erneut mitteilen'
            },
        },
        'home': {
            'title': 'Übersicht: Ablesungen',
            'expireWarning': '<0>Achtung</0> am {{expireDate}} laufen {{expireCount}} Aufträge ab, die du noch nicht abgelesen hast!',
            'statistic': {
                'title': '$t(common.reading_plural)',
                'total': 'Gesamt',
                'done': 'Erledigt',
            },
            'period': {
                'title': 'Zeiträume',
                'description': 'Damit die Verbräuche korrekt abgerechnet werden können, müssen die Zählerstände rechtzeitig an uns übermittelt werden. Hier siehst du die Zeiträume, bis wann wir deinen Zählerstand benötigen:',
                'showMeters': 'Zähler anzeigen',
            },
        },
        'unknownMeterReading': {
            'listItemLabel': 'weiteren Zählerstand erfassen',
            'heading': 'Zählerstand für weiteren Zähler erfassen',
            'description': 'Gern kannst du Zählerstände für Zähler erfassen, welche nicht in dieser Ableseliste angefragt wurden. Diese zusätzlichen Stände werden nach der Erfassung direkt an <1>{{applicationName}}</1> weitergeleitet. Bitte beachte, dass diese zusätzlich erfassten Stände nach der Weiterleitung hier nicht mehr angezeigt werden.',
            'loginDropdown': {
                'optionFallback': 'kein Bezeichner',
                'placeholder': 'Bitte wähle'
            },
            'notAllowedNotification': {
                'title': 'Nicht erlaubt',
                'message': 'Du verfügst nicht über die benötigten Rechte für diese Funktion.'
            },
        },
    },
    'evaluation': {
        'hint': 'Wähle im linken Menü einen Zähler aus, um dessen Auswertung anzuzeigen.',
        'labels': {
            'readingsNotDone': 'Nicht erledigte $t(common.reading_plural)',
            'readingsWithoutEvaluation': 'Nicht auswertbar',
        },
        'details': {
            'title': 'Zählerauswertung',
            'labels': {
                'consumptions': 'Verbräuche',
                'history': 'Ablesehistorie',
                'meterAddress': 'Zähleranschrift',
                'consumption': 'Verbrauch',
                'comparisonToPreviousConsumption': 'Vergleich zum letzten Verbrauch',
            },
        },
        'empty': {
            'noJobs': 'Für die Auswertung liegen aktuell nicht genügend Daten vor.',
            'noReadingEventsForMeterRate': 'Für den Tarif {{rateName}} liegen keine Ableseergebnisse vor.',
            'noReadingEventsForMeterRates': 'Für die Tarife {{rateNames}} liegen keine Ableseergebnisse vor.',
            'noEvaluationPossibleWithRateName': 'Es kann keine Verbrauchsauswertung für den Tarif: {{rateName}} durchgeführt werden.',
            'noEvaluationPossibleNoRateName': 'Es kann keine Verbrauchsauswertung für diesen Tarif durchgeführt werden.',
        },
    },
    'management': {
        'hint': 'Wähle im linken Menü einen Zugang aus, um Aufträge zu teilen.',
        'accounts': {
            'title': 'Meine Zugänge',
            'reminder': {
                'subject': 'Erinnerung zur Ablesung',
                'body': 'Hallo {{name}},\n\n\nwir möchten dich daran erinnern, deine Zählerstände im Ableseportal zu erfassen.\n\nUm zu deinem Ableseportal zu gelangen klicke bitte auf den folgenden Link:\n\n{{login}}\n'
            },
            'labels': {
                'total': 'Gesamt',
            },
            'list': {
                'by': 'von',
            },
            'subaccounts': {
                'empty': 'Dieser Zugang hat keine Aufträge geteilt.',
                'buttons': {
                    'share': 'Aufträge teilen',
                    'shareShort': 'Teilen'
                },
                'overview': {
                    'title': 'Erstellte Zugänge',
                },
                'details': {
                    'openedByToken': 'Diese Freigabe wurde von dir selbst erstellt. Hier siehst du die Aufträge, die darin enthalten sind.',
                    'description': 'Dieser Zugang enthält {{count}} Auftrag',
                    'description_plural': 'Dieser Zugang enthält {{count}} Aufträge',
                    'delete': {
                        'confirm': {
                            'title': 'Freigabe wirklich löschen?',
                            'description': 'Die Freigabe wird gelöscht, Aufträge und Ergebnisse bleiben erhalten.',
                        },
                        'title': 'Ablesung aus Freigabe entfernen?',
                        'description': 'Die Ablesung wird <1>aus der Freigabe</1> entfernt. In deinen Ablesungen bleibt sie weiterhin enthalten.',
                    },
                    'reshare': {
                        'title': 'Neue Zugangsdaten anlegen',
                        'description': 'Willst du weitere Zugangsdaten anlegen? Der bestehende Zugang bleibt erhalten.',
                    },
                },
            },
            'share': {
                'title': 'Aufträge teilen',
                'task': 'Auftrag',
                'task_plural': 'Aufträge',
                'buttons': {
                    'share': '{{count}} Auftrag teilen',
                    'share_plural': '{{count}} Aufträge teilen',
                },
                'error': {
                    'maxjobs': 'Es können maximal {{count}} Aufträge geteilt werden.'
                },
                'loading': 'Aufträge werden geladen...',
                'labels': {
                    // 'status',
                },
                'empty': 'Du hast keine Aufträge, die du teilen darfst',
                'description': 'Bitte wähle die Aufträge, die geteilt werden sollen.',
                'hint': '<0>Tipp:</0> Klicke auf die Spalten-Namen um die Tabelle zu sortieren. Mit einem Klick auf <3/> kannst du Aufträge gruppieren.',
                'tableColumns': {
                    'isShared': "Geteilt"
                },
                'exportXlsx': {
                    'title': 'Ablesungen als Excel-Datei herunterladen',
                    'description': 'Bei Bedarf kannst du die ausgewählten Ableseaufträge als Excel-Datei herunterladen. Bitte erfasse die Stände trotzdem hier, da ein späterer Excel-Upload zur Übernahme der Zählerstände nicht möglich ist.',
                    'label': 'Datei herunterladen',
                    'button': 'Excel-Datei herunterladen',
                    'headers': {
                        'partnerIdentifier': '$t(meterReadingJob.partner.identifier)',
                        'partnerCompany': '$t(meterReadingJob.partner.company)',
                        'customerNumber': '$t(meterReadingJob.customer.customernumber)',
                        'customerCompany': '$t(meterReadingJob.customer.company) GP',
                        'customerMailingAddressStreet': '$t(meterReadingJob.address.street) GP',
                        'customerMailingAddressHouseNumber': '$t(meterReadingJob.address.houseNumber) GP',
                        'customerMailingAddressZip': '$t(meterReadingJob.address.zip) GP',
                        'customerMailingAddressCity': '$t(meterReadingJob.address.city) GP',
                        'maLoId': '$t(meterReadingJob.maLoId)',
                        'meterAddressStreet': 'Verbrauchsstelle $t(meterReadingJob.address.street)',
                        'meterAddressHouseNumber': 'Verbrauchsstelle $t(meterReadingJob.address.houseNumber)',
                        'meterAddressZip': 'Verbrauchsstelle $t(meterReadingJob.address.zip)',
                        'meterAddressCity': 'Verbrauchsstelle $t(meterReadingJob.address.city)',
                        'meterAddressObjectKey': 'Verbrauchsstelle $t(meterReadingJob.objectKey)',
                        'meterAddressLocationDescription': 'Verbrauchsstelle $t(meterReadingJob.address.locationDescription)',
                        'meterAddressHint': '$t(meterReadingJob.address.hint)',
                        'lastMeterReadingDate': 'letztes Ablesedatum',
                        'lastMeterReadingValue': 'letzter Ablesewert',
                        'meterReadingDate': '$t(meterReadingJob.readingDate)',
                        'meterReadingValue': '$t(meterReadingJob.readingValue)',
                        'meterNumber': '$t(meterReadingJob.meterNumber)',
                        'meterType': '$t(meterReadingJob.meterType)',
                        'rateName': '$t(meterReadingJob.rates)',
                        'obis': '$t(meterReadingJob.obisCode)',
                        'targetReadingDate': '$t(common.targetReadingDate)',
                        'latestExecution': '$t(meterReadingJob.jobConstraints.latestExecution)',
                    },
                    'error' : {
                        'title': 'Excel-Export Fehler',
                        'message': 'Beim Export der Excel Daten ist ein Fehler aufgetreten, bitte versuche es später erneut.'
                    }
                },
            },
        },
    },
    'help': {
        'global': {
            'contentTitle': 'Ableseportal - Hilfe',
            'tableOfContents': 'Inhaltsverzeichnis',
            'defaultText': 'Die Hilfe ist in der gewählten Sprache nicht verfügbar.',
        },
        'sidePopOver': {
            'title': 'Hilfe zur Ablesung',
            'button': 'Hilfe',
        },
    },
    'communication': {
        'triggerButton': {
            'registration': 'Hier Anmelden',
            'communication': 'Ablesebestätigung zusenden',
        },
        'home': {
            'title': 'Digitale & papierlose Kommunikation',
            'registration': {
                'description': 'Indem du auf digitale Kommunikation umstellst, kannst du nicht nur die Umwelt schonen und Zeit sparen, sondern auch von effizienteren Prozessen und besserer Nachverfolgbarkeit profitieren.',
                'descriptionBulletPointsTitle': 'Deine Vorteile:',
                'descriptionBulletPoints': [
                    'Bestätigung zur erfolgten Ablesung per E-Mail',
                    'Zusammenstellung aller Informationen zu Deiner Ablesung',
                    'Zukünftig schnelle und unkomplizierte Mitteilungen per E-Mail',
                ],
            },
            'communication': {
                'description': 'Du bist für die digitale Kommunikation angemeldet und kannst die Ablesebestätigung per E-Mail anfordern.',
            }
        },
        'jobDetails': {
            'registration': {
                'description': 'Indem du auf digitale Kommunikation umstellst, kannst du nicht nur die Umwelt schonen und Zeit sparen, sondern auch von effizienteren Prozessen und besserer Nachverfolgbarkeit profitieren.',
            },
            'communication': {
                'description': 'Du bist für die digitale Kommunikation angemeldet und kannst die Ablesebestätigung per E-Mail anfordern.',
            }
        },
        'dialog': {
            'registration': {
                'title': 'Anmeldung zur digitalen & papierlosen Kommunikation',
                'description': 'Um die Ablesebestätigung per E-Mail zu erhalten, melde dich bitte für die digitale Kommunikation an.',
                'content': 'Erhalte deine abgelesenen Zählerstände bequem per E-Mail. Deine Kommunikationseinstellungen kannst Du bei Bedarf jederzeit anpassen.',
                'missingURL': 'Die Anmeldung zur digitalen Kommunikation ist aktuell nicht möglich. Bitte versuche es später erneut.',
                'submitButtonText': 'Anmelden',
                'cancelButtonText': 'Abbrechen',
            },
            'communication': {
                'title': 'Ablesebestätigung per E-Mail erhalten',
                'description': 'Du bist bereits für die digitale Kommunikation angemeldet. Du kannst die Ablesebestätigungen per E-Mail hier anfordern.',
                'content': 'Der Versand der Ablesebestätigung per E-Mail ist für dich kostenfrei. Du hast einen Zähler abgelesen. Möchtest du eine Ablesebestätigung per E-Mail anfordern?',
                'content_plural': 'Der Versand der Ablesebestätigung per E-Mail ist für dich kostenfrei. Du hast bereits {{count}} Zähler abgelesen. Möchtest du eine Ablesebestätigung per E-Mail anfordern?',
                'submitButtonText': 'Ablesebestätigung anfordern',
                'cancelButtonText': 'Abbrechen',
            }
        },
        'notification': {
            'registration': {
                'done': {
                    'title': 'Erfolgreich zur digitalen Kommunikation angemeldet',
                    'description': 'Ab sofort kannst du dir eine Ablesebestätigung per E-Mail zusenden lassen, nachdem du einen Zähler abgelesen hast.'
                }
            },
            'communication': {
                'done': {
                    'title': 'Ablesebestätigung erfolgreich angefordert',
                    'description': 'Die Ablesebestätigung für einen Zähler wurde an dich per E-Mail versandt.',
                    'description_plural': 'Die Ablesebestätigung für {{count}} Zähler wurde an dich per E-Mail versandt.',
                },
                'error': {
                    'title': 'Anfrage für Ablesebestätigung fehlgeschlagen',
                    'description': 'Der Versand der Ablesebestätigung per E-Mail für einen Zähler konnte nicht ausgeführt werden. Bitte versuche es später erneut.',
                    'description_plural': 'Der Versand der Ablesebestätigung per E-Mail für {{count}} Zähler konnte nicht ausgeführt werden. Bitte versuche es später erneut.',
                }
            }
        }
    },
    'meterReadingJob': {
        'type': 'Typ',
        'status': 'Status',
        'meter': 'Zähler',
        'meLoId': 'Messlokation',
        'maLoId': 'Marktlokation',
        'meterNumber': 'Zählernummer',
        'meterType': 'Zählerart',
        'rates': 'Tarife',
        'obisCode': 'OBIS',
        'objectKey': 'Objekt',
        'readingDate': 'Ablesedatum',
        'readingValue': 'Ablesewert',
        'jobConstraints': {
            'earliestExecution': 'Ablesung ab',
            'latestExecution': 'Ablesefrist',
        },
        'partner': {
            'title': 'Partner',
            'identifier': 'Partner-Nr.',
            'company': 'Partner-Firma',
            'name': 'Name',
        },
        'customer': {
            'title': 'Kunde',
            'customernumber': 'Kundennummer',
            'company': 'Firma',
            'name': 'Name',
            'firstname': 'Vorname',
            'lastname': 'Nachname',
        },
        'address': {
            'title': 'Adresse',
            'fullAddress': 'Zähleranschrift',
            'street': 'Straße',
            'houseNumber': 'Hausnummer',
            'zip': 'PLZ',
            'city': 'Stadt',
            'cityDistrict': 'Stadtteil',
            'hint': 'Ablesehinweis',
            'locationDescription': 'Beschreibung',
        },
        'aggregation': {
            'street': '{{count}} Straße',
            'street_plural': '{{count}} Straßen',
            'zip': '{{count}} PLZ',
            'zip_plural': '{{count}} PLZ',
            'city': '{{count}} Stadt',
            'city_plural': '{{count}} Städte',
            'cityDistrict': '{{count}} Stadtteil',
            'cityDistrict_plural': '{{count}} Stadtteile',
            'customernumber': '{{count}} KdNr',
            'customernumber_plural': '{{count}} Kdnr',
            'objectKey': '{{count}} Objekt',
            'objectKey_plural': '{{count}} Objekte',
            'meter': '{{count}} Zähler',
            'meter_plural': '{{count}} Zähler',
            'latestExecution': '{{count}} Ablesefrist',
            'latestExecution_plural': '{{count}} Ablesefristen',
        },
    },
    'offline': {
        'warning': 'Bitte schließe den Browser und diesen Tab nicht!',
        'description' : 'Deine Ablesung kann momentan nicht gesendet werden. Sobald eine Verbindung besteht, werden die Aufträge nachträglich gesendet.',
        'details': 'Du kannst weitere Ablesungen durchführen. Sobald dein Gerät wieder über eine Internetverbindung verfügt, werden alle Ablesungen gesendet.\n\n\nNoch nicht gesendete Ablesungen werden in einem Statusfenster angezeigt.',
        'infopanel' : {
            'message' : '{{queueLength}} Ablesung wurden noch nicht gesendet',
            'message_plural' : '{{queueLength}} Ablesungen wurden noch nicht gesendet',
            'details' : 'Die Aufträge werden gesendet, sobald eine Internetverbindung besteht.'
        },
        'buttons' : {
            'close': 'Hinweis ausblenden',
            'retry': 'Erneut versuchen'
        }
    },
    'menu': {
        'home': 'Startseite',
        'reading': 'Ablesung',
        'evaluation': 'Auswertung',
        'management': 'Verwaltung',
        'help': 'Hilfe',
        'accounts': 'Meine Zugänge',
        'logout': 'Abmelden',
    },
    'common': {
        'metertypes': {
            'ELECTRIC_METER': 'Stromzähler',
            'HEAT_METER': 'Wärmemengenzähler',
            'GAS_METER': 'Gaszähler',
            'WATER_METER': 'Wasserzähler',
            'WATER_WARM_METER': 'Warmwasserzähler',
            'GENERIC_METER': 'allg. Zähler',
        },
        'reasons': {
            'INITIAL_READING': 'Einbauzählerstand',
            'PERIODIC_READING': 'Turnusablesung',
            'ESTIMATED_READING': 'Schätzung',
            'INTERMEDIATE_READING': 'Zwischenablesung',
            'METER_CHANGE': 'Zählerwechsel',
            'OTHER': 'andere Ablesung',
            'OTHER_KSA': 'Kundenselbstablesung',
            'null': 'Unbekannter Grund'
        },
        'jobs': '{{count}} Auftrag',
        'jobs_plural': '{{count}} Aufträge',
        'reading': 'Ablesung',
        'reading_plural': 'Ablesungen',
        'progress': 'Fortschritt',
        'targetReadingDate': 'Zielablesedatum',
        'loadingReadings': 'Lade Ablesungen...',
        'loadingJobs': 'Lade Aufträge...',
        'search': 'Suche',
        'total': 'Gesamt',
        'until': 'bis',
        'of': 'von',
        'loading': 'wird geladen...',
        'dialog': {
            'ok': 'OK',
            'abort': 'Abbrechen',
        },
        'requiredField': 'Pflichtfeld',
        'fillRequiredFields': 'Bitte Pflichtfelder (*) angeben.',
        'account': {
            'noName': 'Mein Zugang',
        },
        'day': 'Tag'
    },
};
